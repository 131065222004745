import React from "react";
import  "./Signup.css" ;
import Crypto from '../assets/trade.png'

function Signup() {
  return (
    <>
      <div className="signup">
        <div className="container">
          <div className="left">
            <img src={Crypto} alt="" />
          </div>
          <div className="right">
            <h2>Earn Passive income with Crypto</h2>
            <p>Earn up to -50% annual rewards on 30+ digital assets.</p>

            <div className="input-container">
              <input type="email" placeholder="Enter Your Email" />
              <button className="btn">Learn More</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
